import React from 'react'
import { Link } from 'react-router-dom'

export default function ProudctList() {
  return (
    <>
    <section className="w3l-witemshny-main py-5">
        <div className="container py-md-4">

            <div className="witemshny-grids row">
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="services/architecture-design" className="d-block zoom">
                            <img src="assets/images/d1.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="witemshny-inf">
                        </div>
                    </div>
<h4 className="gdnhy-1 mt-4"><Link to="services/design-services">
Architecture Design
                    </Link>
                    </h4>
                </div>
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="services/construction-documentation" className="d-block zoom">
                            <img src="assets/images/d5.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="witemshny-inf">
                        </div>
                    </div>
<h4 className="gdnhy-1 mt-4"><Link to="services/construction-documentation">Construction
                    </Link>
                    </h4>
                </div>
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="services/project-management" className="d-block zoom">
                        <img src="assets/images/d3.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="witemshny-inf">
                        </div>
                    </div>
                    <h4 className="gdnhy-1 mt-4"><Link to="services/project-management">
                    Project Management</Link>
                    </h4>
                </div>
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="/services/consultation-services" className="d-block zoom">
                        <img src="assets/images/d4.jpg" alt="" className="img-fluid news-image"/></Link>
                        
                        <div className="witemshny-inf">
                        </div>
                    </div>
<h4 className="gdnhy-1 mt-4"><Link to="/services/consultation-services">Consultation Services
                    </Link>
                    </h4>
                </div>
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="/services/consultation-services" className="d-block zoom">
                            <img src="assets/images/d2.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="witemshny-inf">
                        </div>
                    </div>
                    <h4 className="gdnhy-1 mt-4"><Link to="services/renovation-and-remodeling-services">Renovation and Remodeling Services
                    </Link>
                    </h4>
                </div>
                <div className="col-xl-2 col-md-4 col-6 product-incfhny mt-4">
                    <div className="weitemshny-grid oposition-relative">
                        <Link to="services/interior-design" className="d-block zoom">
                        <img src="assets/images/d6.jpg" alt="" className="img-fluid news-image"/></Link>
                            
                        <div className="witemshny-inf">
                        </div>
                    </div>
<h4 className="gdnhy-1 mt-4">
<Link to="services/interior-design">
    Interior Design</Link>
  </h4>
                </div>
            </div>
           

        </div>
    </section>
   
    

      
    </>
  )
}
