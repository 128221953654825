import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function BookCoWorkingSpaceServices() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Book a Co-working Space</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Book a Co-working Space
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Flexible Workspace Solutions</h6>
                <h3 className="title-w3l mb-4">Book Your Ideal Co-working Space</h3>
                <p className="mt-3 pe-lg-5">
                  Our co-working space booking service offers a variety of shared office spaces that cater to freelancers, startups, and remote teams. Whether you're looking for a dedicated desk, a private office, or a shared environment, we have options to suit your needs.
                  <br />
                  Enjoy a collaborative environment with modern amenities, high-speed internet, and a professional atmosphere to boost your productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Workspaces Across Prime Locations</h6>
              <h3 className="title-w3l mb-3">Find the Perfect Co-working Space</h3>
            </div>
            <div className="row">
              {/* <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/cowork1.webp" alt="Private Office" />
                      <div className="box-content text-center">
                        <h3 className="title">Private Office</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div> */}
              <div className="col-lg-4 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/co1.jpg" alt="Dedicated Desk" />
                      <div className="box-content text-center">
                        <h3 className="title">Dedicated Desk</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/co2.jpg" alt="Shared Workspace" />
                      <div className="box-content text-center">
                        <h3 className="title">Shared Workspace</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/co3.jpg" alt="Meeting Rooms" />
                      <div className="box-content text-center">
                        <h3 className="title">Meeting Rooms</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Book Effortlessly</h6>
            <h3 className="title-w3l">Our Booking Process</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Explore Locations</a>
                  </h4>
                  <p className="text-para">Browse top co-working spaces across prime locations worldwide.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-building"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Compare Options</a>
                  </h4>
                  <p className="text-para">Choose from a variety of spaces and amenities that fit your work needs.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-check-circle"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Instant Booking</a>
                  </h4>
                  <p className="text-para">Book instantly with flexible terms and secure payment options.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
}
