import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function ConsultationServices() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Consultation Services</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Consultation Services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Expert Construction Consultation</h6>
                <h3 className="title-w3l mb-4">Tailored Advice for Construction Projects</h3>
                <p className="mt-3 pe-lg-5">
                  Our consultation services offer expert guidance for all stages of your construction project. Whether
                  you're in the planning phase, dealing with architectural designs, or tackling complex project
                  challenges, our team of experienced consultants is here to help you every step of the way.
                  <br />
                  From feasibility studies to risk assessment and budgeting, we provide personalized advice tailored to
                  your project's unique needs.
                </p>
                {/* <div className="w3banner-content-btns">
                  <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">
                    Learn More <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                  <a href="contact.html" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">
                    Contact Us <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                </div> */}
              </div>
              {/* <div className="col-lg-6 passion-grid-item-info">
                <img src="../assets/images/consultation.jpg" alt="Consultation Services" className="img-fluid radius-image" />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Comprehensive Consultation</h6>
              <h3 className="title-w3l mb-3">Consultation for Every Phase</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon1.webp" alt="Planning" />
                      <div className="box-content text-center">
                        <h3 className="title">Planning</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon2.webp" alt="Design" />
                      <div className="box-content text-center">
                        <h3 className="title">Design</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon3.webp" alt="Execution" />
                      <div className="box-content text-center">
                        <h3 className="title">Execution</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon4.webp" alt="Post-Completion" />
                      <div className="box-content text-center">
                        <h3 className="title">Post-Completion</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Expert Advice for Every Stage</h6>
            <h3 className="title-w3l">Consultation Expertise</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-clipboard-check"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Feasibility Studies</a>
                  </h4>
                  <p className="text-para">In-depth feasibility studies to assess project viability.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-chart-pie"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Risk Assessments</a>
                  </h4>
                  <p className="text-para">Thorough risk assessments to identify and mitigate project risks.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Budgeting & Cost Control</a>
                  </h4>
                  <p className="text-para">Effective budgeting and cost management strategies for projects.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
}
