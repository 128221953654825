import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

const BannerSlider = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    items: 1,
  };

  return (
    <section className="w3l-main-slider banner-slider" id="home">
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item">
          <div className="slider-info banner-view banner-top1">
            <div className="container">
              <div className="banner-info header-hero-19">
                <p className="w3hny-tag">Real Estate is our life</p>
                <h3 className="title-hero-19">The creativity of the new world.</h3>
                <Link to="/services/architecture-design" className="btn btn-style btn-primary mt-4">
                Discover More <i className="fas fa-angle-double-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slider-info banner-view banner-top2">
            <div className="container">
              <div className="banner-info header-hero-19">
                <p className="w3hny-tag">Innovative Property Solutions</p>
                <h3 className="title-hero-19">Building your dream home.</h3>
                <Link to="/services/construction-documentation" className="btn btn-style btn-primary mt-4">
                Discover More <i className="fas fa-angle-double-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slider-info banner-view banner-top3">
            <div className="container">
              <div className="banner-info header-hero-19">
                <p className="w3hny-tag">Your Trust, Our Responsibility</p>
                <h3 className="title-hero-19">Experience excellence in real estate.</h3>
                <Link to="/services/project-management" className="btn btn-style btn-primary mt-4">
                  Discover More <i className="fas fa-angle-double-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default BannerSlider;
