
import Footer from './Components/Footer';
import Home from './Components/Home';
import NavBar from './Components/NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Service from './Components/Service';
import ArchitectureDesign from './Components/Services/ArchitectureDesign';
import ConstructionDocumentation from './Components/Services/ConstructionDocumentation';
import ProjectManagement from './Components/Services/ProjectManagement';
import ConsultationServices from './Components/Services/ConsultationServices';
import RenovationRemodeling from './Components/Services/RenovationRemodeling';
import SpecializedArchitecturalServices from './Components/Services/SpecializedArchitecturalServices';
import ContactUs from './Components/ContactUs';
import AboutUs from './Components/AboutUs';
import Interiordesign from './Components/Services/Interiordesign';
import BookHotelServices from './Components/Services/BookHotelServices';
import BookCoWorkingSpaceServices from './Components/Services/BookCoWorkingSpaceServices';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    
<Router>
<Toaster />
<NavBar/>
<Routes>
  <Route path="/" element={<Home/>} />
  <Route path="/service" element={<Service/>}/>
  <Route path="/services/architecture-design" element={<ArchitectureDesign/>}/>
  <Route path="/services/construction-documentation" element={<ConstructionDocumentation/>}/>
  <Route path="/services/project-management" element={<ProjectManagement/>}/>
  <Route path="/services/consultation-services" element={<ConsultationServices/>}/>
  <Route path="services/renovation-and-remodeling-services" element={<RenovationRemodeling/>}/>
  <Route path="/services/specialized-architectural-services" element={<SpecializedArchitecturalServices />}/>
  <Route path="/services/book-Hotel" element={<BookHotelServices />}/>
  
  <Route path="/services/interior-design" element={<Interiordesign />}/>
  <Route path="/services/book-coworking" element={<BookCoWorkingSpaceServices />}/>
  
<Route path="/contactus" element={<ContactUs/>}/>
<Route path="/aboutus" element={<AboutUs />}/>
  </Routes>
  <Footer/>
   </Router>
 );
}

export default App;
