import React, { useState } from 'react'
import { toast } from 'react-hot-toast';

import { Link } from 'react-router-dom'

export default function ContactForm() {
    // const alertMessage=()=>toast('Successfully!')

    const[FormData,setFormData]=useState({
    name:"",
    email:"",
    phone:"",
    message:""
})   

const [responseMessage,setresponseMessage]=useState('');
const [ErrorMessage,setErrorMessage]=useState('');

const handleInputChange=(e)=>{
    const {name,value}=e.target;
    setFormData({...FormData,
        [name]:value});
}

const handleSubmit= async  (e)=>{
    e.preventDefault();
    // console.log('Form submitted:', FormData);
    try{
        const response=await fetch("https://spaceyfi.com/api/contactus",{
            method:"post",
            headers:{'Content-Type': 'application/json'},
            body: JSON.stringify(FormData) // Send the form data as JSON


        });
        if (response.ok){
           // setresponseMessage('Message sent successfully!');
            toast.success('Message sent successfully!');
            setFormData({ name: '', subject: '', email: '', phone: '', message: '' }); 
        }else{
            const errorData = await response.json();
setErrorMessage(errorData.error || 'Something went wrong, please try again.');
            
        }
        
    }catch(error){
        setErrorMessage('Server error, please try again later.');
    }

}
  return (
    <>
      <div className="w3l-contact-10 py-5" id="contact">
        <div className="form-41-mian pt-lg-4 pt-md-3 pb-lg-4">
            <div className="container">
                <div className="heading text-center mx-auto">
                    <h5 className="title-subw3hny text-center">Contact our team</h5>
                    <h3 className="title-w3l">Got any <span className="inn-text">Questions? </span></h3>
                </div>

                <div className="contacts-5-grid-main mt-5">
                    <div className="contacts-5-grid">
                        <div className="map-content-5">
                            <div className="d-grid grid-col-2 justify-content-center">
                                <div className="contact-type">
                                    <div className="address-grid">
                                        <span className="fas fa-map-marked-alt"></span>
                                        <h6>Address</h6>
                                        <p>
                                        Tehri Tower,Saraswati Vihar, Ajabpur Khurd, Dehradun, Uttarakhand 248121
                                        </p>


                                    </div>
                                    <div className="address-grid">
                                        <span className="fas fa-envelope-open-text"></span>
                                        <h6>Email</h6>
                                        <a href="mailto:spaceyfiindia@gmail.com" className="link1">spaceyfiindia@gmail.com</a>
                                        {/* <a href="mailto:mydroidgems@gmail.com" className="link1">mydroidgems@gmail.com</a> */}

                                    </div>
                                    <div className="address-grid">
                                        <span className="fas fa-phone-alt"></span>
                                        <h6>Phone</h6>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
    {/* Phone Number 1 */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="tel:+91 96349 71680" className="link1">+91 96349 71680</Link>
        <Link to="https://wa.link/5bk8zy" target='_blank' style={{ marginLeft: '10px' }}>
            <i className="fa fa-whatsapp" style={{ color: 'green', fontSize: '24px' }} aria-hidden="true"></i>
        </Link>
    </div>

    {/* Phone Number 2 */}
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <Link to="tel:+91 98126 29484" className="link1">+91 98126 29484</Link>
        <Link to="https://wa.link/24aaig" target='_blank' style={{ marginLeft: '10px' }}>
            <i className="fa fa-whatsapp" style={{ color: 'green', fontSize: '24px' }} aria-hidden="true"></i>
        </Link>
    </div>
</div>

                                        {/* <Link to="tel:096349 71680" className="link1">096349 71680</Link>
                                        
                                        <Link to="tel:+91 98126 29484" className="link1">+91 98126 2948</Link>
                                        <div style={{ display: 'flex', alignItems: 'center',  }}>
                                        <Link to="https://wa.link/5bk8zy" target='_blank'><i className="fa fa-whatsapp" style={{ color: 'red',fontSize:"24px" }} aria-hidden="true"></i>
                                        </Link>
                                        <Link to="https://wa.link/24aaig" target='_blank'><i className="fa fa-whatsapp" style={{ color: 'red',fontSize:"24px" }} aria-hidden="true"></i>
                                        </Link>
                                        </div> */}

                                       



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-inner-cont mt-5">
                    <form onSubmit={handleSubmit}  className="signin-form">
                        <div className="form-grids">
                            <div className="form-input">
                                <input type="text" name="name" id="name" 
                                value={FormData.name} onChange={handleInputChange} placeholder="Enter your name *" required="" />
                            </div>
                            <div className="form-input">
                                <input type="text" name="subject" id="subject"
                                 value={FormData.subject} onChange={handleInputChange} placeholder="Enter subject " required />
                            </div>
                            <div className="form-input">
                                <input type="email" name="email" id="email"
                                value={FormData.email} onChange={handleInputChange} placeholder="Enter your email *" required />
                            </div>
                            <div className="form-input">
                                <input type="text" name="phone" id="phone"
                                value={FormData.phone} onChange={handleInputChange} placeholder="Enter your Phone Number *" required />
                            </div>
                        </div>
                        <div className="form-input">
                            <textarea name="message" id="message"
                            value={FormData.message} onChange={handleInputChange} placeholder="Type your query here" required=""></textarea>
                        </div>
                        <div className="w3-submit text-right">
                            <button className="btn btn-style btn-primary">Send Message here <i className="fas fa-paper-plane ms-2"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       
    </div>
      
    </>
  )
}
