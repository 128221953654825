import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function SpecializedArchitecturalServices() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Specialized Architectural Services</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Specialized Architectural Services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Innovative Architectural Solutions</h6>
                <h3 className="title-w3l mb-4">Specialized Architectural Services Tailored to Your Vision</h3>
                <p className="mt-3 pe-lg-5">
                  Our specialized architectural services focus on delivering custom-designed solutions that balance
                  creativity, functionality, and sustainability. From conceptual designs to detailed execution plans,
                  our team of skilled architects works closely with clients to bring their vision to life.
                  <br />
                  We provide architectural expertise for residential, commercial, and industrial projects, ensuring
                  each design aligns with both aesthetic and practical goals.
                </p>
                <div className="w3banner-content-btns">
                  {/* <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">
                    Learn More <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                  <a href="contact.html" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">
                    Contact Us <i className="fas fa-angle-double-right ms-2"></i>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6 passion-grid-item-info">
                <img src="../assets/images/architecture.jpg" alt="Specialized Architectural Services" className="img-fluid radius-image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Architectural Design Excellence</h6>
              <h3 className="title-w3l mb-3">Our Specialized Services</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon1.webp" alt="Conceptual Design" />
                      <div className="box-content text-center">
                        <h3 className="title">Conceptual Design</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon2.webp" alt="3D Visualization" />
                      <div className="box-content text-center">
                        <h3 className="title">3D Visualization</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon3.webp" alt="Sustainable Design" />
                      <div className="box-content text-center">
                        <h3 className="title">Sustainable Design</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon4.webp" alt="Urban Planning" />
                      <div className="box-content text-center">
                        <h3 className="title">Urban Planning</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Architectural Expertise</h6>
            <h3 className="title-w3l">Our Architectural Specialties</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-pencil-ruler"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Custom Design</a>
                  </h4>
                  <p className="text-para">Tailored architectural designs to suit individual client needs.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-cubes"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Modular Design</a>
                  </h4>
                  <p className="text-para">Innovative modular design solutions for flexibility and functionality.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-city"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Urban Development</a>
                  </h4>
                  <p className="text-para">Urban design and planning for sustainable and efficient cities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
}
