import React from 'react'
import ProudctList from './ProudctList';
import BannerSlider from './BannerSlider';
import { Link } from 'react-router-dom';

 function Home() {
  return (
   <>
   {/* <h1>Feature1 branch</h1> */}
  <BannerSlider/>
   {/* <section className="w3l-main-slider banner-slider" id="home">
        <div className="owl-one owl-carousel owl-theme">
            <div className="item">
                <div className="slider-info banner-view banner-top1">
                    <div className="container">
                        <div className="banner-info header-hero-19">
                            <p className="w3hny-tag">Real Estate is our life </p>
                            <h3 className="title-hero-19">The creativity of the new world.</h3>
                            <a href="./" className="btn btn-style btn-primary mt-4">Read More <i className="fas fa-angle-double-right ms-2"></i></a>

                        </div>
                    </div>
                </div>
            </div>


        </div>


    </section> */}
    <ProudctList/>
    <div className=" w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-4">
            <div className="row">
                <div className="col-md-6 mt-md-0">
                    <div className="grids3-info position-relative">
                        <Link to="services/book-coworking" className="d-block zoom">
                            <img src="assets/images/coworking.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="w3-grids3-info">
                            <h4 className="gdnhy-1"><Link to="services/book-coworking">Book a Co-Working <br/>Space</Link>
                                <Link className="w3item-link btn btn-style mt-4" to="services/book-coworking">
                              
                                    Explore Property <i className="fas fa-angle-double-right ms-2"></i>
                                </Link>
                                

                            </h4>
                             {/* Adding Location Icon and Dehradun */}
      <div className="location-info mt-3">
        <i className="fas fa-map-marker-alt" style={{ color: 'red' }}></i> <span style={{ color: 'white' }}>Dehradun</span>
      </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-md-0 mt-4 grids3-info2">
                    <div className="grids3-info position-relative">
                        <Link  className="d-block zoom">
                            <img src="assets/images/banner3.jpg" alt="" className="img-fluid news-image"/></Link>
                        <div className="w3-grids3-info second">
                            <h4 className="gdnhy-1"><Link  to="services/book-Hotel">Book  a <br/>Hotel</Link>
                                <Link className="w3item-link btn btn-style mt-4" to="services/book-Hotel">
                                    Explore Property <i className="fas fa-angle-double-right ms-2"></i>
                                </Link>
                            </h4>
                            <div className="location-info mt-3">
        <i className="fas fa-map-marker-alt" style={{ color: 'red' }}></i> <span style={{ color: 'white' }}>Dehradun</span>
      </div>
                            

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <section className="w3l-grids-3 py-5" id="about">
        <div className="container py-md-5 py-3">
            <div className="row bottom-ab-grids">
                <div className="w3l-video-left col-lg-6" id="video">

                    <div className="w3l-index5">
                        <div className="position-relative mt-4">
                            {/* <a href="#small-dialog" className="popup-with-zoom-anim play-view text-center position-absolute">
                                <span className="video-play-icon">
                                    <span className="fa fa-play"></span>
                                </span>
                            </a> */}
                          
                            <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                                {/* <iframe src="https://player.vimeo.com/video/23512331" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
                            </div>
							
                        </div>
                    
                    </div>
                </div>
                <div className="w3ab-left-top col-lg-6 mt-lg-0 mt-5 ps-lg-5">
                    <h6 className="title-subw3hny mb-1">Expert Room Transformations for a Fresh New Look

</h6>
                    <h3 className="title-w3l mb-2">Revitalize Your Room, Reimagine Your Space

</h3>
                    <p className="my-3"> 
                    Breathe new life into your room with a design overhaul that combines style, comfort, and practicality. </p>
                    <p className="my-3"> Whether you're aiming to modernize your space, improve its flow, or add personal touches, our team of design professionals is ready to bring your ideas to life.</p>
                    {/* <a href="about.html" className="btn btn-style btn-primary mt-4">Explore Buying <i className="fas fa-angle-double-right ms-2"></i></a> */}
                </div>
            </div>
        </div>
    </section>
    <section className="w3l-passion-mid-sec py-5 pt-0">
        <div className="container py-md-5 py-3 pt-0">
            <div className="container">
                <div className="row w3l-passion-mid-grids">
                    <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                        <h6 className="title-subw3hny mb-1">Transforming Spaces into Luxury, Functionality, and Comfort

</h6>
                        <h3 className="title-w3l mb-4">Creating Premium Properties That Inspire

</h3>
                        <p className="mt-3 pe-lg-5">At Specify, we believe that every property should reflect elegance, quality, and superior craftsmanship. Our approach to premium properties combines innovative design, meticulous attention to detail, and a focus on creating spaces that are not only visually stunning but also functional and comfortable.</p>
                        {/* <div className="w3banner-content-btns">
                            <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">Property For Free <i className="fas fa-angle-double-right ms-2"></i></a>

                        </div> */}
                    </div>
                    <div className="col-lg-6 passion-grid-item-info">
<img src="assets/images/g6.jpg" alt="" className="img-fluid radius-image"/>
                    </div>
                </div>

            </div>
        </div>
    </section>
    

    <section class="w3l-join-main py-5">
        <div class="container py-md-5">
            <div class="w3l-project-in">
                <div class="bottom-info text-center pb-lg-5">
                    <div class="header-section pe-lg-5">
                        {/* <h6 class="title-subw3hny mb-2">Join With Us</h6> */}
                        <h3 class="title-w3l two mb-2">Revolutionizing Real Estate with AI


                        </h3>
                        <p class="mb-2">Smart Technology for Data-Driven Decisions and Property Management

</p>
                        <div class="w3banner-content-btns pb-5 pb-5">
                            <Link to="/contactus" class="btn btn-style btn-primary mt-4"> Get In Touch <i class="fas fa-angle-double-right ms-2"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="w3l-features py-5" id="features">
        <div class="container py-lg-5 py-md-4 py-2">
            <div class="title-content text-center mb-lg-3 mb-4">
                <h6 class="title-subw3hny mb-1">What We Do</h6>
                <h3 class="title-w3l">We're on a Mission to Change
                    View of RealEstate Field.</h3>
            </div>
            {/* <div class="main-cont-wthree-2">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div class="grids-1 box-wrap">
                            <div class="icon">
                                <i class="fas fa-pen-fancy"></i>
                            </div>
                            <h4><a href="#service" class="title-head mb-3">Your One-Stop Shop for Finding Your Dream Home</a></h4>
                            <p class="text-para">Lorem ipsum dolor sit amet, elit. Id ab commodi magnam. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div class="grids-1 box-wrap">
                            <div class="icon">
                                <i class="fas fa-layer-group"></i>
                            </div>
                            <h4><a href="#service" class="title-head mb-3">Schedule a Free, No-Obligation Appointment</a></h4>
                            <p class="text-para">Lorem ipsum dolor sit amet, elit. Id ab commodi magnam. </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div class="grids-1 box-wrap">
                            <div class="icon">
                                <i class="fas fa-house-user"></i>
                            </div>
                            <h4><a href="#service" class="title-head mb-3">Understand the Value of Your Property</a></h4>
                            <p class="text-para">Lorem ipsum dolor sit amet, elit. Id ab commodi magnam. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="main-cont-wthree-2">
  <div className="row justify-content-center">
    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
      <div className="grids-1 box-wrap">
        <div className="icon">
          <i className="fas fa-drafting-compass"></i>
        </div>
        <h4>
          <a href="#service" className="title-head mb-3">
            Tailored Property Solutions
          </a>
        </h4>
        <p className="text-para">
          We provide customized property services, from acquisition to renovation, designed to meet your unique needs.
        </p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
      <div className="grids-1 box-wrap">
        <div className="icon">
          <i className="fas fa-tools"></i>
        </div>
        <h4>
          <a href="#service" className="title-head mb-3">
            Comprehensive Construction Management
          </a>
        </h4>
        <p className="text-para">
          From project planning to execution, our construction management ensures timely delivery and quality results.
        </p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
      <div className="grids-1 box-wrap">
        <div className="icon">
          <i className="fas fa-building"></i>
        </div>
        <h4>
          <a href="#service" className="title-head mb-3">
            Expert Renovation and Remodeling
          </a>
        </h4>
        <p className="text-para">
          Our team specializes in transforming spaces with premium renovation and remodeling services that exceed expectations.
        </p>
      </div>
    </div>
  </div>
</div>


        </div>
    </section>
  
    
   
    
   
  
    
    
    {/* <section class="w3l-blog">
        <div class="blog py-5" id="Newsblog">
            <div class="container py-lg-5 py-md-4 py-2">
                <div class="title-content text-center mb-lg-3 mb-4">
                    <h6 class="title-subw3hny mb-1">Providing Smart Solutions and Top Results in Building and Property Care

</h6>
                    <h3 class="title-w3l mb-5">Crafting Excellence in Every Project

</h3>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 item">
                        <div class="card">
                            <div class="card-header p-0 position-relative">
                                <a href="#blog" class="zoom d-block">
<img class="card-img-bottom d-block"
 src="assets/images/g3.jpg" alt="Card image cap"/>
                                </a>
                            </div>
                            <div class="card-body blog-details">

                                <a href="#blog" class="blog-desc">Private Contemporary Home Balancing Openness</a>
                                <p>Lorem ipsum viverra feugiat. Pellen tesque libero ut justo.</p>
                            </div>
                            <div class="card-footer">
                                <div class="author align-items-center">
                                    <a href="#author" class="post-author">
<img src="assets/images/team1.jpg" alt=""
 class="img-fluid rounded-circle"/>
                                    </a>
                                    <ul class="blog-meta">
                                        <li>
                                            <span class="meta-value">by</span><a href="#author"> David Marks</a>
                                        </li>
                                    </ul>
                                    <div class="date">
                                        <p>20 Oct, 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 item mt-md-0 mt-5">
                        <div class="card">
                            <div class="card-header p-0 position-relative">
                                <a href="#blog" class="zoom d-block">
<img class="card-img-bottom d-block" 
src="assets/images/g4.jpg" alt="Card image cap" />
                                </a>
                            </div>
                            <div class="card-body blog-details">

                                <a href="#blog" class="blog-desc">How Does A Designer Home Look Like</a>
                                <p>Lorem ipsum viverra feugiat. Pellen tesque libero ut justo.</p>
                            </div>
                            <div class="card-footer">
                                <div class="author align-items-center">
                                    <a href="#author" class="post-author">
<img src="assets/images/team2.jpg"
 alt="" class="img-fluid rounded-circle"/>
                                    </a>
                                    <ul class="blog-meta">
                                        <li>
                                            <span class="meta-value">by</span><a href="#author"> Lynda Stone</a>
                                        </li>
                                    </ul>
                                    <div class="date">
                                        <p>22 Oct, 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 item mt-lg-0 mt-5">
                        <div class="card">
                            <div class="card-header p-0 position-relative">
                                <a href="#blog" class="zoom d-block">
<img class="card-img-bottom d-block" 
src="assets/images/g2.jpg" alt="Card image cap"/>
                                </a>
                            </div>
                            <div class="card-body blog-details">

                                <a href="#blog" class="blog-desc">Private Contemporary Home Balancing Openness</a>
                                <p>Lorem ipsum viverra feugiat. Pellen tesque libero ut justo.</p>
                            </div>
                            <div class="card-footer">
                                <div class="author align-items-center">
                                    <a href="#author" class="post-author">
 <img src="assets/images/team3.jpg"
  alt="" class="img-fluid rounded-circle"/>
                                    </a>
                                    <ul class="blog-meta">
                                        <li>
                                            <span class="meta-value">by</span><a href="#author"> David Nelson</a>
                                        </li>
                                    </ul>
                                    <div class="date">
                                        <p>23 Oct, 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
   </>
  )
}
export default Home;