import React from 'react'
import { Link } from 'react-router-dom'
import ContactForm from './ContactForm'
import ProudctList from './ProudctList'

export default function Service() {
  return (
    <>
    
    <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
            <div className="container">
                <div className="w3breadcrumb-gids">
                    <div className="w3breadcrumb-left text-left">
                        <h2 className="inner-w3-title mt-sm-5 mt-4">
                            Services </h2>

                    </div>
                    <div className="w3breadcrumb-right">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="index.html">Home</Link></li>
                            <li className="active"><span className="fas fa-angle-double-right mx-2"></span> Services </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>
    <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
            <div className="container">
                <div className="row w3l-passion-mid-grids">
                    <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                        <h6 className="title-subw3hny mb-1">Choose your services</h6>
                        {/* <h3 className="title-w3l mb-4">Property owners get free posting when they register</h3> */}
                        <p className="mt-3 pe-lg-5">At Spaceyfi , we pride ourselves on our extensive experience and commitment to excellence in the construction industry. With over 12 years of expertise, our dedicated team specializes in a diverse range of projects, including residential developments, group housing, hotels, and infrastructure initiatives.

Our approach is rooted in a deep understanding of our clients' needs and aspirations. We believe that each project is an opportunity to create lasting value and enhance communities. Our skilled professionals bring a wealth of knowledge and craftsmanship to every project, ensuring high standards of quality, safety, and sustainability.</p>
                        {/* <div className="w3banner-content-btns">
                            <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">Services <i className="fas fa-angle-double-right ms-2"></i></a>
                            <a href="contact.html" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">Contact Us <i className="fas fa-angle-double-right ms-2"></i></a>
                        </div> */}
                    </div>
                    <div className="col-lg-6 passion-grid-item-info">
                        <img src="assets/images/g6.jpg" alt="" className="img-fluid radius-image"/>
                    </div>
                </div>

            </div>
        </div>
    </section>
   <ProudctList/>
    
    <ContactForm/>

    </>
  )
}
