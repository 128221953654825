import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function RenovationRemodeling() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Renovation & Remodeling Services</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Renovation & Remodeling Services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Transform Your Space</h6>
                <h3 className="title-w3l mb-4">Expert Renovation and Remodeling Services</h3>
                <p className="mt-3 pe-lg-5">
                  Whether you’re looking to renovate your home, office, or commercial property, our team of experts
                  specializes in giving spaces a fresh, modern, and functional makeover. We handle all aspects of the
                  renovation and remodeling process, ensuring that your space meets the highest standards of quality and
                  design.
                  <br />
                  From kitchen upgrades to full-scale home remodeling, our services are tailored to meet your specific
                  needs.
                </p>
                <div className="w3banner-content-btns">
                  {/* <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">
                    Learn More <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                  <a href="contact.html" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">
                    Contact Us <i className="fas fa-angle-double-right ms-2"></i>
                  </a> */}
                </div>
              </div>
              {/* <div className="col-lg-6 passion-grid-item-info">
                <img src="../assets/images/renovation.jpg" alt="Renovation and Remodeling" className="img-fluid radius-image" />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Complete Renovation Services</h6>
              <h3 className="title-w3l mb-3">From Concept to Completion</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon1.webp" alt="Planning" />
                      <div className="box-content text-center">
                        <h3 className="title">Planning</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon2.webp" alt="Design" />
                      <div className="box-content text-center">
                        <h3 className="title">Design</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon3.webp" alt="Execution" />
                      <div className="box-content text-center">
                        <h3 className="title">Execution</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon4.webp" alt="Post-Completion" />
                      <div className="box-content text-center">
                        <h3 className="title">Post-Completion</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Expert Remodeling Services</h6>
            <h3 className="title-w3l">Our Remodeling Expertise</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-home"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Home Renovations</a>
                  </h4>
                  <p className="text-para">Transforming your home into a modern, functional, and stylish space.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-building"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Commercial Remodeling</a>
                  </h4>
                  <p className="text-para">Upgrading office spaces, retail stores, and commercial properties.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-hammer"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Custom Renovations</a>
                  </h4>
                  <p className="text-para">Tailored renovations designed to meet your exact specifications.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
}
