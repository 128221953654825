import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">About Us</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li><Link to="/">Home</Link></li>
                  <li className="active"><span className="fas fa-angle-double-right mx-2"></span> About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-index-about py-5" id="about2">
        <div className="container py-md-5 py-2">
          <div className="row">
            <div className="col-lg-6 pe-lg-5">
              <div className="w3l-abouthny-img">
                <img src="assets/images/team1.jpg" alt="Team" className="img-fluid radius-image" />
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <div className="w3l-abouthny-info">
                <h6 className="title-subw3hny">Who we are</h6>
                <h3 className="title-w3l">The experts in local and international property</h3>
                <p className="mt-4">
                At Spaceyfi , we pride ourselves on our extensive experience and commitment to excellence in the construction industry. With over 12 years of expertise, our dedicated team specializes in a diverse range of projects, including residential developments, group housing, hotels, and infrastructure initiatives.

Our approach is rooted in a deep understanding of our clients' needs and aspirations. We believe that each project is an opportunity to create lasting value and enhance communities. Our skilled professionals bring a wealth of knowledge and craftsmanship to every project, ensuring high standards of quality, safety, and sustainability.

From the initial design phase to the final touches, we are committed to delivering results that exceed expectations. At Spaceyfi,we don’t just build structures; we create environments that inspire and elevate the human experience. Join us on a journey to transform visions into reality.
                </p>
                <p className="mt-3">We offer outstanding property services with a focus on social responsibility and expert advice.</p>
                <ul className="w3l-right-book w3l-right-book-grid mt-md-5 mt-4">
                  <li><span className="fas fa-check"></span> Outstanding property</li>
                  <li><span className="fas fa-check"></span> Social responsibility</li>
                  <li><span className="fas fa-check"></span> Get expert advice</li>
                  <li><span className="fas fa-check"></span> Group structure</li>
                  <li><span className="fas fa-check"></span> Specialist services</li>
                  <li><span className="fas fa-check"></span> Vision & strategy</li>
                </ul>
                <Link to="/service" className="btn btn-style btn-primary mt-4">
                  Discover our services <i className="fas fa-angle-double-right ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-stats-section py-5" id="stats">
        <div className="container py-md-4">
          <div className="w3l-stats-inner-inf">
            <div className="row stats-con">
              <div className="col-lg-3 col-6 stats_info counter_grid">
                <p className="counter">100</p>
                <h3>Completed Properties</h3>
              </div>
              <div className="col-lg-3 col-6 stats_info counter_grid">
                <p className="counter">100</p>
                <h3>Property Sales</h3>
              </div>
              <div className="col-lg-3 col-6 stats_info counter_grid mt-lg-0 mt-4">
                <p className="counter">120</p>
                <h3>Apartment Rent</h3>
              </div>
              <div className="col-lg-3 col-6 stats_info counter_grid mt-lg-0 mt-4">
                <p className="counter">100</p>
                <h3>Happy Clients</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
