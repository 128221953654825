import React from 'react'
import ContactForm from './ContactForm'

export default function ContactUs() {
  return (
    <>
   <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
            <div className="container">
                <div className="w3breadcrumb-gids">
                    <div className="w3breadcrumb-left text-left">
                        <h2 className="inner-w3-title mt-sm-5 mt-4">
                            Contact Us </h2>

                    </div>
                    <div className="w3breadcrumb-right">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="./">Home</a></li>
                            <li className="active"><span className="fas fa-angle-double-right mx-2"></span> Contact</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>

<ContactForm/>
    </>
  )
}
