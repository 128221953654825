import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function ProjectManagement() {
  return (
    <>
    <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Project Management</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Project Management
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Comprehensive Project Oversight</h6>
                <h3 className="title-w3l mb-4">Ensuring Success with Professional Project Management</h3>
                <p className="mt-3 pe-lg-5">
                  Effective project management is key to completing construction projects on time, within budget, and
                  with the highest standards. Our project management services oversee every phase, from initiation to
                  closing, ensuring smooth communication, timely execution, and cost management.
                  <br />
                  We focus on coordinating resources, managing risks, and aligning the team to meet project goals.
                </p>
                <div className="w3banner-content-btns">
                  <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">
                    Learn More <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                  <a href="/contact" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">
                    Contact Us <i className="fas fa-angle-double-right ms-2"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 passion-grid-item-info">
                <img src="../assets/images/g6.jpg" alt="Project Management" className="img-fluid radius-image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Project Phases and Milestones</h6>
              <h3 className="title-w3l mb-3">Complete Project Management Services</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon1.webp" alt="Initiation" />
                      <div className="box-content text-center">
                        <h3 className="title">Initiation</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon2.webp" alt="Planning" />
                      <div className="box-content text-center">
                        <h3 className="title">Planning</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon3.webp" alt="Execution" />
                      <div className="box-content text-center">
                        <h3 className="title">Execution</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/icon4.webp" alt="Closing" />
                      <div className="box-content text-center">
                        <h3 className="title">Closing</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Professional Project Management</h6>
            <h3 className="title-w3l">Our Management Expertise</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-chart-line"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Time & Budget Management</a>
                  </h4>
                  <p className="text-para">Precise control over timelines and budget allocation.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-tasks"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Task Coordination</a>
                  </h4>
                  <p className="text-para">Ensuring seamless coordination between different project tasks.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-users-cog"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Team Leadership</a>
                  </h4>
                  <p className="text-para">Managing and leading teams for successful project execution.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<ContactForm/>

      </>
                         

  )
}
