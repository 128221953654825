import React from 'react'
import { Link } from 'react-router-dom'
export default function ArchitectureDesign() {
  return (
    <>
     <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
            <div className="container">
                <div className="w3breadcrumb-gids">
                    <div className="w3breadcrumb-left text-left">
                        <h2 className="inner-w3-title mt-sm-5 mt-4">
                        Architecture-Design </h2>

                    </div>
                    <div className="w3breadcrumb-right">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="./">Home</Link></li>
                            <li className="active"><span className="fas fa-angle-double-right mx-2"></span> Architecture-Design </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>
   
      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
            <div className="container">
                <div className="row w3l-passion-mid-grids">
                    <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                    <h6 className="title-subw3hny mb-1">Comprehensive Architectural Solutions</h6>
<h3 className="title-w3l mb-4">Transforming Concepts into Reality with Tailored Design Services</h3>
<p className="mt-3 pe-lg-5">
  Conceptual Design: Developing the initial design concept based on the client’s vision, needs, and budget. This includes sketches, design proposals, and 3D visualizations.
  Schematic Design: Refining the conceptual design into preliminary drawings, including floor plans, elevations, and sections that reflect the spatial relationships and overall form.
  Detailed Design: Creating more detailed architectural drawings and specifications for the project,
</p>
                        {/* <div className="w3banner-content-btns">
                            <a href="about.html" className="btn btn-style btn-primary mt-lg-5 mt-4 me-2">Services <i className="fas fa-angle-double-right ms-2"></i></a>
                            <a href="contact.html" className="btn btn-style btn-outline-dark mt-lg-5 mt-4">Contact Us <i className="fas fa-angle-double-right ms-2"></i></a>
                        </div> */}
                    </div>
                    <div className="col-lg-6 passion-grid-item-info">
                        <img src="../assets/images/g6.jpg" alt="" className="img-fluid radius-image"/>
                    </div>
                </div>

            </div>
        </div>
        
    </section>
    <section className="locations-1 w3services-3">
        <div className="locations py-5">
            <div className="container py-lg-5 py-md-4 py-2">
                <div className="heading text-center mx-auto">
                    <h6 className="title-subw3hny mb-1">Design to Delivery



</h6>
                    <h3 className="title-w3l mb-3">Complete Construction Services



</h3>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-6 mt-md-5 mt-4">
                        <div className="w3property-grid">
                            <a href="#property">
                                <div className="box16">
<img className="img-fluid" src="../assets/images/icon1.webp" alt=""/>
                                    <div className="box-content text-center">
                                        {/* <i className="fas fa-home"></i> */}
                                        <h3 className="title">Planning </h3>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mt-md-5 mt-4">
                        <div className="w3property-grid">
                            <a href="#property">
                                <div className="box16">

<img className="img-fluid" src="../assets/images/icon2.webp" alt=""/>
                                    <div className="box-content text-center">
                                        {/* <i className="far fa-building"></i> */}
                                        <h3 className="title">Design </h3>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mt-md-5 mt-4">
                        <div className="w3property-grid">
                            <a href="#property">
                                <div className="box16">

<img className="img-fluid" src="../assets/images/icon3.webp" alt=""/>
                                    <div className="box-content text-center">
                                        {/* <i className="fas fa-house-damage"></i> */}
                                        <h3 className="title">Engineering </h3>

                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                    <div className="col-lg-3 col-6 mt-md-5 mt-4">
                        <div className="w3property-grid">
                            <a href="#property">
                                <div className="box16">
                                    <img 
                                    className="img-fluid" 
                                    src="../assets/images/icon4.webp" 
                                    alt=""/>
                                    <div className="box-content text-center">
                                        {/* <i className="fas fa-hotel"></i> */}
                                        <h3 className="title">Management  </h3>

                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
    <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
            <div className="title-content text-center mb-lg-3 mb-4">
                <h6 className="title-subw3hny mb-1">Delivering Quality and Tailored Solutions for Every Project

</h6>
                <h3 className="title-w3l">Our Expertise</h3>
            </div>
            <div className="main-cont-wthree-2">
                <div className="row">
                    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div className="grids-1 box-wrap">
                            <div className="icon">
                            <i className="fas fa-hands-helping"></i>

                            </div>
                            <h4><a href="#" className="title-head mb-3">Expert Guidance</a></h4>
                            <p className="text-para">
                            Personalized support at every stage, ensuring your project’s success.

</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div className="grids-1 box-wrap">
                            <div className="icon">
                            <i className="fas fa-pencil-ruler"></i>

                            </div>
                            <h4><a href="#" className="title-head mb-3">
                            Tailored Design Solutions
                            </a></h4>
                            <p className="text-para">
                            Creative, functional designs that bring your vision to life.

</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                        <div className="grids-1 box-wrap">
                            <div className="icon">
                            <i className="fas fa-gem"></i>

                            </div>
                            <h4><a href="#service" className="title-head mb-3">
                            Premium Quality & Value
                            </a></h4>
                            <p className="text-para">
                            Delivering top-notch craftsmanship and long-lasting value in every project.

</p>
                        </div>
                    </div>
                    
                    
              
                </div>
            </div>
        </div>
    </section>
    <div className="w3l-contact-10 py-5" id="contact">
        <div className="form-41-mian pt-lg-4 pt-md-3 pb-lg-4">
            <div className="container">
                <div className="heading text-center mx-auto">
                    <h5 className="title-subw3hny text-center">Contact our team</h5>
                    <h3 className="title-w3l">Got any <span className="inn-text">Questions? </span></h3>
                </div>

                <div className="contacts-5-grid-main mt-5">
                    <div className="contacts-5-grid">
                        <div className="map-content-5">
                            <div className="d-grid grid-col-2 justify-content-center">
                                <div className="contact-type">
                                    <div className="address-grid">
                                        <span className="fas fa-map-marked-alt"></span>
                                        <h6>Address</h6>
                                        <p>#302, 5th Floor, VHLY-2247 ek,RealHouzing, New York.</p>

                                    </div>
                                    <div className="address-grid">
                                        <span className="fas fa-envelope-open-text"></span>
                                        <h6>Email</h6>
                                        <a href="mailto:mailone@example.com" className="link1">mailone@example.com</a>
                                        <a href="mailto:mailtwo@example.com" className="link1">mailtwo@example.com</a>

                                    </div>
                                    <div className="address-grid">
                                        <span className="fas fa-phone-alt"></span>
                                        <h6>Phone</h6>
                                        <a href="tel:+12 324-016-695" className="link1">+12 324-016-695</a>
                                        <a href="tel:+44 224-058-545" className="link1">+44 224-058-545</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-inner-cont mt-5">
                    <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="signin-form">
                        <div className="form-grids">
                            <div className="form-input">
                                <input type="text" name="w3lName" id="w3lName" placeholder="Enter your name *" required="" />
                            </div>
                            <div className="form-input">
                                <input type="text" name="w3lSubject" id="w3lSubject" placeholder="Enter subject " required />
                            </div>
                            <div className="form-input">
                                <input type="email" name="w3lSender" id="w3lSender" placeholder="Enter your email *" required />
                            </div>
                            <div className="form-input">
                                <input type="text" name="w3lPhone" id="w3lPhone" placeholder="Enter your Phone Number *" required />
                            </div>
                        </div>
                        <div className="form-input">
                            <textarea name="w3lMessage" id="w3lMessage" placeholder="Type your query here" required=""></textarea>
                        </div>
                        <div className="w3-submit text-right">
                            <button className="btn btn-style btn-primary">Send Message <i className="fas fa-paper-plane ms-2"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       
    </div>
    </>
      

  )
}
