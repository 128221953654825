import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';

export default function BookHotelServices() {
  return (
    <>
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-sm-5 mt-4">Book a Hotel</h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> Book a Hotel
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-passion-mid-sec py-5 pb-0">
        <div className="container py-md-5 py-3 pb-0">
          <div className="container">
            <div className="row w3l-passion-mid-grids">
              <div className="col-lg-6 passion-grid-item-info pe-lg-5 mb-lg-0 mb-5">
                <h6 className="title-subw3hny mb-1">Luxury Stays at Affordable Prices</h6>
                <h3 className="title-w3l mb-4">Book Your Dream Hotel Stay</h3>
                <p className="mt-3 pe-lg-5">
                  Our hotel booking service offers a wide range of options to fit your travel needs, from budget-friendly hotels to luxury resorts. Whether you're planning a vacation, business trip, or a weekend getaway, we make the booking process seamless and easy.
                  <br />
                  Explore our hotel and enjoy personalized recommendations based on your preferences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="locations-1 w3services-3">
        <div className="locations py-5">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="heading text-center mx-auto">
              <h6 className="title-subw3hny mb-1">Wide Range of Options</h6>
              <h3 className="title-w3l mb-3">Choose from Top Hotels Worldwide</h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/hotel1.webp" alt="Luxury Hotels" />
                      <div className="box-content text-center">
                        <h3 className="title">Luxury Hotels</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/hotel2.webp" alt="Boutique Hotels" />
                      <div className="box-content text-center">
                        <h3 className="title">Boutique Hotels</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/hotel3.webp" alt="Resorts" />
                      <div className="box-content text-center">
                        <h3 className="title">Resorts</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-md-5 mt-4">
                <div className="w3property-grid">
                  <a href="#property">
                    <div className="box16">
                      <img className="img-fluid" src="../assets/images/hotel4.webp" alt="Budget Hotels" />
                      <div className="box-content text-center">
                        <h3 className="title">Budget Hotels</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Book with Confidence</h6>
            <h3 className="title-w3l">Our Booking Process</h3>
          </div>
          <div className="main-cont-wthree-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-search-location"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Search Hotels</a>
                  </h4>
                  <p className="text-para">Browse through thousands of hotel options with real-time availability.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-hand-holding-usd"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Compare Prices</a>
                  </h4>
                  <p className="text-para">Get the best rates from different booking platforms.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-check-circle"></i>
                  </div>
                  <h4>
                    <a href="#" className="title-head mb-3">Instant Booking</a>
                  </h4>
                  <p className="text-para">Book your hotel instantly with secure payment options.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
}
