import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer class="w3l-footer9">
        <section class="footer-inner-main py-5">
            <div class="container py-md-4">
                <div class="right-side">
                    <div class="row footer-hny-grids sub-columns">
                        <div class="col-lg-3 sub-one-left">
                            <h6>About </h6>
                            <p class="footer-phny pe-lg-5">
                            We are a team of dedicated professionals committed to delivering exceptional services in architecture, construction, project management, and interior design. 
</p>
                            <div class="columns-2 mt-4 pt-lg-2">
                                <ul class="social">
                                    <li><Link to="#facebook"><span class="fab fa-facebook-f"></span></Link>
                                    </li>
                                    <li><Link to="#linkedin"><span class="fab fa-linkedin-in"></span></Link>
                                    </li>
                                    <li><Link to="#twitter"><span class="fab fa-twitter"></span></Link>
                                    </li>
                                    <li><Link to="#google"><span class="fab fa-google-plus-g"></span></Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-2 sub-two-right">
                            <h6>Company</h6>
                            <ul>
                           <li><Link to="/aboutus"><i class="fas fa-angle-right"></i>About Us</Link>
                                </li>
                                <li><Link to="/contactus"><i class="fas fa-angle-right"></i>Contact Us
                                    </Link>
                                </li>
                                <li><Link to="/service"><i class="fas fa-angle-right"></i>Services
                                    </Link></li>

                                

                            </ul>
                        </div>
                        <div class="col-lg-2 sub-two-right">
                        <h6>Services</h6>
<ul>
    <li><Link to="/services/architecture-design"><i className="fas fa-angle-right"></i> Architecture Design</Link></li>
    <li><Link to="/services/construction-documentation"><i className="fas fa-angle-right"></i> Construction</Link></li>
    <li><Link to="services/project-management"><i className="fas fa-angle-right"></i> Project Management</Link></li>
    </ul>

                        </div>
                        <div class="col-lg-2 sub-two-right">
                        <h6>Services</h6>
<ul>
    <li><Link to="/services/construction-documentation"><i className="fas fa-angle-right"></i> Consultation Services</Link></li>
    <li><Link to="/services/renovation-and-remodeling-services"><i className="fas fa-angle-right"></i> Renovation and Remodeling Services</Link></li>
    <li><Link to="/services/interior-design"><i className="fas fa-angle-right"></i> Interior Design</Link></li>
</ul>

                        </div>
                        <div class="col-lg-3 sub-one-left ps-lg-5">
                            <h6>Stay Update!</h6>
                            <p class="w3f-para mb-4">Subscribe to our newsletter to receive our weekly feed.</p>
                            {/* <div class="w3l-subscribe-content align-self mt-lg-0 mt-5">
                                <form action="#" method="post" class="subscribe-wthree">
                                    <div class="flex-wrap subscribe-wthree-field">
<input class="form-control" type="email" 
placeholder="Email" name="email" required=""/>
                                        <button class="btn btn-style btn-primary" type="submit">Subscribe</button>
                                    </div>
                                </form>
                            </div> */}


                        </div>
                    </div>
                </div>
                <div class="below-section mt-5 pt-lg-3">
                    <div class="copyright-footer">
                        <div class="columns text-left">
                            <p>© 2024 Specify. All rights reserved. Design by <Link to="https://github.com/parmjeet1/" target="_blank">
                                    Paramjeet</Link></p>
                        </div>
                        <ul class="footer-w3list text-right">
                            <li><Link to="#url">Privacy Policy</Link>
                            </li>
                            <li><Link to="#url">Terms &amp; Conditions</Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>

        <button onclick="topFunction()" id="movetop" title="Go to top">
            <span class="fas fa-level-up-alt" aria-hidden="true"></span>
        </button>
    </footer>
      
    </>
  )
}
