
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
     useEffect(() => {
        const handleNavToggle = () => {
          document.body.classList.toggle('noscroll');
        };
    
        const navbarToggler = document.querySelector('.navbar-toggler');
        if (navbarToggler) {
          navbarToggler.addEventListener('click', handleNavToggle);
        }
    
        return () => {
          if (navbarToggler) {
            navbarToggler.removeEventListener('click', handleNavToggle);
          }
        };
    }, []);
  return (

  
    <header id="site-header" className="fixed-top">
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light stroke py-lg-0">
<h1><Link className="navbar-brand" to="">
                      <img src='./assets/images/logo.png'/>
                        {/* Real<span className="sub-color">Houzing</span> */}
                    </Link></h1>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                    <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav mx-lg-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/aboutus">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/service">Services</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/contactus">Contact</Link>
                        </li>
                    </ul>

                    
                   
                </div>
           
                
            </nav>
        </div>
    </header>

      
   
  )
}
